import { Chain } from "viem";
import { config } from "./config";
import { polygonMumbai } from "viem/chains";
import { polygon } from "wagmi/chains";

export const ZERO_ADDRRESS = "0x0000000000000000000000000000000000000000";
export const MANA_TOKEN_CONTRACT_ADDRESS = config.get(
  "MANA_TOKEN_CONTRACT_ADDRESS"
)! as `0x${string}`;
export const TOWN_TOKEN_CONTRACT_ADDRESS = config.get(
  "TOWN_TOKEN_CONTRACT_ADDRESS"
)! as `0x${string}`;
export const AUCTION_HOUSE_CONTRACT_ADDRESS = config.get(
  "AUCTION_HOUSE_CONTRACT_ADDRESS"
)! as `0x${string}`;
export const EXODUS_DAO_CONTRACT_ADDRESS = config.get(
  "EXODUS_DAO_CONTRACT_ADDRESS"
)! as `0x${string}`;

export function getChain(): Chain {
  const chainId = config.get("CHAIN_ID");
  switch (chainId) {
    case "80001": {
      return polygonMumbai;
    }
    case "168587773": { // Chain ID for blast-sepolia
      console.log("Chain ID is blast");
      return {
        id: 168587773,
        name: "Blast Sepolia",
        network: "Blast Sepolia",
        nativeCurrency: {
          name: "ETH",
          symbol: "ETH",
          decimals: 18,
        },
        rpcUrls: {
          alchemy: { // Assuming the structure is the same, adjust if needed
            http: ["https://sepolia.blast.io"],
            webSocket: [], // Add WebSocket URL if available
          },
          infura: { // Adjust according to availability
            http: ["https://sepolia.blast.io"],
            webSocket: [],
          },
          default: {
            http: ["https://sepolia.blast.io"],
          },
          public: { // Adjust according to availability
            http: ["https://sepolia.blast.io"],
          },
        },
        blockExplorers: { // Adjust if a block explorer is available
          etherscan: {
            name: "Blastscan", // Block Explorer name
            url: "https://testnet.blastscan.io", // Block Explorer URL
          },
          default: {
            name: "Blastscan",
            url: "https://testnet.blastscan.io",
          }
        },
      };
    }
    default: {
      console.log("Default polygon");
      return polygon;
    }
  }
}


export function getContractUrl(contractAddress: string) {
  return `${config.get("BLOCK_EXPLORER_URL")}/address/${contractAddress}`;
}