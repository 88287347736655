import { w3mConnectors, w3mProvider } from "@web3modal/ethereum";
import { configureChains, createConfig } from "wagmi";
import { getChain } from "./eth";

export const walletConnectProjectId = "aa6da3989e874f80205726b306fc2fb6";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [getChain()],
  [w3mProvider({ projectId: walletConnectProjectId })]
);

export const config = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({
    chains,
    projectId: walletConnectProjectId,
  }),
  publicClient,
  webSocketPublicClient,
});

export { chains };
