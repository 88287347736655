import { memo, useCallback, useEffect } from "react";
import { formatEther, parseUnits } from "viem"; // Make sure this import is correct
import { erc20ABI, useAccount, useContractRead, useContractWrite, useDisconnect } from "wagmi";
import { Container, Mana, Menu, UserMenu, Button } from "decentraland-ui"; // Ensure Button is correctly imported
import { Network } from "@dcl/schemas";
import { useAvatar } from "../modules/avatar";
import { useLogin } from "../modules/login";
import { useAuction } from "../modules/auction";
import { MANA_TOKEN_CONTRACT_ADDRESS } from "../eth";
import "./Navbar.css";

export const Navbar = memo(() => {
  const { address, isConnected } = useAccount();
  const { login, isLoggingIn } = useLogin();
  const { disconnect } = useDisconnect();
  const { avatar } = useAvatar(address);
  const { auction } = useAuction();

  const {
    data: mana,
    isLoading: isLoadingBalance,
    refetch,
  } = useContractRead({
    address: MANA_TOKEN_CONTRACT_ADDRESS,
    abi: erc20ABI,
    functionName: "balanceOf",
    args: [address!],
  });


  const getFreeTokensABI = [
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "getFreeTokens",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    }
  ];

  const amountInWei = parseUnits("100", 18);

  const { 
    write: getFreeTokens, 
    isLoading: isGettingFreeTokens, 
    error: getFreeTokensError
  } = useContractWrite({
    address: '0x44d635c836b380A3cAd70678512d23d357E9e884',
    abi: getFreeTokensABI,
    functionName: 'getFreeTokens',
    args: [amountInWei],
  });

  const handleGetFreeTokensClick = useCallback(() => {
    console.log('Button clicked. isConnected:', isConnected, 'isGettingFreeTokens:', isGettingFreeTokens);
    if (isConnected && !isGettingFreeTokens) {
      console.log('Attempting to call getFreeTokens...');
      getFreeTokens();
    }
  }, [getFreeTokens, isConnected, isGettingFreeTokens]);

  // Debugging useEffect for getFreeTokens
  useEffect(() => {
    if (getFreeTokensError) {
      console.error('Error calling getFreeTokens:', getFreeTokensError);
    }
  }, [getFreeTokensError]);

  useEffect(() => {
    console.log('isGettingFreeTokens status:', isGettingFreeTokens);
  }, [isGettingFreeTokens]);
  
  

  const balance = mana ? +formatEther(mana) | 0 : 0;
  const prettyBalance = balance.toLocaleString();

  useEffect(() => {
    if (auction) {
      refetch();
    }
  }, [auction, refetch]);

  return (
    <div className="dcl navbar Navbar">
      <Container className="row">
        <Menu secondary className="dcl navbar-menu">
          <div className="dcl navbar-logo">
            <div className="dcl logo"></div>
          </div>
          <Menu.Item href="https://decentraland.org/play?realm=bagworlds.com">
            Play
          </Menu.Item>
          <Menu.Item href={"https://dg-live-landing-git-feat-new-mark-f392dd-cuentaparavtv-gmailcom.vercel.app/"}>
            MARKET
          </Menu.Item>
          <Menu.Item href={"https://snapshot.org/#/decentralgames.eth"}>DAO</Menu.Item>
          <Menu.Item href="https://twitter.com/DecentralGames">NEWS</Menu.Item>
        </Menu>
        <div className="dcl navbar-account">
          <Menu secondary className="dcl navbar-account-menu">
            {isConnected ? (
              <>
                {isLoadingBalance ? null : (
                  <Mana inline network={Network.MATIC}>
                    {prettyBalance}
                  </Mana>
                )}
                <UserMenu
                  address={address}
                  avatar={avatar}
                  onSignOut={disconnect}
                  isSignedIn
                />
                {/* The new button for getting free tokens */}
                <Button onClick={handleGetFreeTokensClick} loading={isGettingFreeTokens} disabled={!isConnected}>
                  Get free testbag tokens
                </Button>
              </>
            ) : (
              <Menu.Item disabled={isLoggingIn} onClick={login}>
                Sign In
              </Menu.Item>
            )}
          </Menu>
        </div>
      </Container>
    </div>
  );
});
