import { memo, useEffect, useState } from "react";
import { Button, Container } from "decentraland-ui";
import { SlMagnifier } from "react-icons/sl";
import { IoMdLogIn } from "react-icons/io";
import { FaDiscord } from "react-icons/fa";
import { Navbar } from "../components/Navbar";
import { Auction } from "../components/Auction";
import { Tiles } from "../components/Tiles";
import { Accordion } from "../components/Accordion";
import { useAuction } from "../modules/auction";
import { useTreasury } from "../modules/treasury";
import { INVITE_LINK, useDiscord } from "../modules/discord";
import { useOnline } from "../modules/online";
import { config } from "../config";
import "./HomePage.css";

export const HomePage = memo(() => {
  const { maxTokenId } = useAuction();
  const [tokenId, setTokenId] = useState<string>();
  const { members, isLoading: isLoadingMembers } = useDiscord();
  const { treasury, isLoading: isLoadingTreasury } = useTreasury();
  const { users, isLoading: isLoadingUsers } = useOnline();

  useEffect(() => {
    setTokenId(maxTokenId.toString());
  }, [maxTokenId]);

  return (
    <>
      <Navbar />
      <Tiles tokenId={tokenId} setTokenId={setTokenId} />
      <div className="HomePage dcl page">
        <Container className="content">
          <Auction tokenId={tokenId} setTokenId={setTokenId} />
          <div className="content">
            <div className="welcome">
              <h1 className="title">
                Welcome to Bags Worlds{" "}
                <Button
                  size="large"
                  primary
                  className="jump-in"
                  href="https://decentraland.org/play?realm=bagworlds.com"
                >
                  <span className="text">Jump In</span>{" "}
                  <i className="jump-in-icon" />
                </Button>
              </h1>
              <p>
              BAGS Worlds is the premier metaverse on the Blast Network, where everyone can build and contribute. Beyond launching an NFT store, buying real products in 3D shopping environments with top brands, and playing multiplayer games, you have the power to craft your own experiences. Start by acquiring your first land!{" "}
                <b>Join us!</b>.
              </p>
              <p>
                Every 24 hours, a BAGS NFT token is auctioned off in exchange for
                BAGs, granting the holder the ability to publish content to
                Bags Worlds. 
              </p>
            </div>

            <Button
              size="large"
              primary
              className="jump-in-mobile"
              href="https://decentraland.org/play?realm=bagworlds.com"
            >
              <span className="text">Jump In</span>{" "}
              <i className="jump-in-icon" />
            </Button>

            <div className="stats">
              <div className="stat">
                <h2>Treasury</h2>
                <p className="value">
                  {isLoadingTreasury
                    ? "Loading..."
                    : `$${(+(treasury?.toFixed(2) || "0")).toLocaleString()}`}
                  <Button
                    href={`${config.get(
                      "BLOCK_EXPLORER_URL"
                    )}/tokenholdings?a=${config.get(
                      "EXODUS_DAO_CONTRACT_ADDRESS"
                    )}`}
                    target="_blank"
                    primary
                    size="small"
                    inverted
                    className="discord"
                  >
                    <SlMagnifier className="magnifier-icon" /> View
                  </Button>
                </p>
              </div>

              <div className="stat">
                <h2>Community</h2>
                <p className="value">
                  {isLoadingMembers ? "Loading..." : `${members} Members`}{" "}
                  <Button
                    href={INVITE_LINK}
                    target="_blank"
                    primary
                    size="small"
                    inverted
                    className="discord"
                  >
                    <FaDiscord className="discord-icon" /> Join
                  </Button>
                </p>
              </div>

              {users > 0 ? (
                <div className="stat">
                  <h2>Online</h2>
                  <p className="value">
                    {isLoadingUsers
                      ? "Loading..."
                      : users === 1
                      ? `1 User`
                      : `${users} Users`}{" "}
                    <Button
                      href="https://decentraland.org/play?realm=bagworlds.com"
                      target="_blank"
                      primary
                      size="small"
                      inverted
                      className="online"
                    >
                      <IoMdLogIn className="jump-in-icon" />
                      Jump In
                    </Button>
                  </p>
                </div>
              ) : null}
            </div>

            <div className="learn-more">
              <h2>Learn More</h2>

              <Accordion title="The Token">
                <p>
                  The BAGS token allows its holder to publish content on BAGS 
                  Worlds parcels.Parcels
                  in Bags Worlds are equivalent to a 3x3 Estate.
                </p>
              </Accordion>
              <Accordion title="The Auction">
                <p>
                  Bags Worlds features daily auctions in which one BAGS NFT token is
                  made available for purchase using Bags every 24 hours.
                </p>
              </Accordion>
              <Accordion title="The Editor">
                <p>
                  The Web Editor is integrated into Bags worlds,
                  allowing content to be created and published directly onto
                  parcels. The editor is open to everyone and doesn't require
                  coding skills.
                </p>
              </Accordion>
          
            </div>

          </div>
        </Container>
      </div>
    </>
  );
});
